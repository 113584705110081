<template>
    <div class="px-4">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h1 class="form-heading text-lg-left">Client Profile</h1>
        </div>

        <div class="row" if="client-detail">
            <div class="col-md-8">
                <div class="ma-auto border">
                    <div class="row no-gutters px-2 mt-2">
                        <h1 class="form-heading text-lg-left">Documents:</h1>
                    </div>
                    
                    <div class="row no-gutters px-2 mt-2">
                        <div v-if="computedDocumentList.length > 0" @click="handleRowClick($event)" class="cursor-pointer w-100">
                            <data-table ref="datatable" :key="computedDocumentListKey" :fields="headers" :data="computedDocumentList" :table-name="'Client Documents'"></data-table>
                        </div>

                        <div v-else class="d-flex flex-wrap justify-between mb-2">
                            <p>No documents available</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="p-3 py-2 ma-auto border">
                    <div class="w-100">
                        <h1 class="form-heading text-lg-left">New Document:</h1>
                    </div>

                    <div class="row no-gutters w-100">
                        <div id="document-form" class="mt-auto">
                            <div class="col-md-12 m-1 mx-0 px-0 py-1">
                                <input type="text" v-model="customFileName" placeholder="Enter custom file name" class="form-control w-100">
                            </div>

                            <div id="uploadDocument" class="col-md-12 row no-gutters mx-0 px-0">    
                                <button type="button" class="btn col-md-5 mr-auto mr-2 text-white w-100 custom-file-upload" @click="triggerFileInputClick"> Choose File </button>
                                <button type="button" class="btn col-md-5 ml-auto ml-2 text-white w-100" @click="uploadDocument"> Upload </button>
                                <input id="fileInput" type="file" style="display: none;" @change="handleFileChange">
                            </div>

                            <div v-if="fileSelected" class="col-md-12">
                                File selected: {{ selectedFileName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
</template>



<script>
    import DataTable from "@/components/common/dataTable/DataTableDataMode";

    export default {
        name: 'allDocuments',
        components: {
            DataTable,
        },
        data() {
            return {
                headers: [
                    { "name": "document_name", "title": "Document Name", "type": "text", "class": "something" },
                    { "name": "document_type", "title": "Document Type", "type": "text"},
                    { "name": "file_name", "title": "File Name", "type": "text"},
                    { "name": "created_at", "title": "Created At", "type": "date"},
                ],
                customFileName: null,
                dialog: false,
                dialogAction: "view",
                fileSelected: false,
                isEverythingReady: false,
                selectedFileName: '',
                uploading: false,
                documentList: [],
            }
        },
        mounted() {
            this.getDocumentList();
        },
        computed: {
            computedDocumentListKey() {
                return this.documentList.length;
            },
            computedDocumentList() {
                return this.documentList;
            }
        },
        methods: {
            async uploadDocument() {
                if (!this.fileSelected) {
                    this.showNotification('error', 'There is not file selected to be uploaded, please select a file. If this issue persists please contact support.');
                    return;
                }

                const fileData = await this.readFileAsync(this.fileSelected);
                const documentName = this.customFileName ? this.customFileName : this.selectedFileName;

                const formData = new FormData();
                formData.append('timezone', 'Europe/London');
                formData.append('device_type', 'web');
                formData.append('client_id', this.$route.params.clientId);
                formData.append('document_meta_data[document_reference]', this.documentReference);
                formData.append('document_meta_data[document_name]', documentName);
                formData.append('document_meta_data[file_name]', this.fileSelected.name);
                formData.append('document_meta_data[document_type]', 'other');
                formData.append('document', this.fileSelected);
                formData.append('document_contents', JSON.stringify(fileData));

                this.$http.post(this.url + "document/upload-document", formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                })
                .then(response => {

                    this.getDocumentList();

                    this.isEverythingReady = true;
                    this.fileSelected = null;
                    this.selectedFileName = '';
                    this.uploading = false;
                    this.customFileName = null;

                    document.getElementById('fileInput').value = '';
                    this.$toastr('success', 'The document was uploaded successfully');
                })
                .catch(error => {
                    this.$toastr('error', 'There was an issue uploading this document, please try again. If this issue persists please contact support.');
                    this.customFileName = null;
                    this.fileSelected = null;
                    this.selectedFileName = '';
                    this.uploading = false;
                });
            },
            downloadDocument(documentId, filename) {
                this.$http.post(this.url + "document/download-document", {
                    timezone: 'Europe/London',
                    device_type: 'web',
                    client_id: this.$route.params.clientId,
                    document_id: documentId,
                })
                .then(response => {
                    if (response.data.status == 200) {
                        const byteCharacters = atob(response.data.content);
                        const byteNumbers = new Array(byteCharacters.length);

                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }

                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: "application/octet-stream" });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement("a");

                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();

                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);

                        this.$toastr('success', 'The document was downloaded successfully');
                    } else {
                        this.$toastr('error', response.data);
                    }
                })
                .catch(error => {
                    this.$toastr('error', 'There was an issue getting the document list, please try again. If this issue persists please contact support.');
                });
            },
            getDocumentList() {
                this.$http.get(this.url + "document/get-document-list", {
                    params: {
                        timezone: this.TimeZone,
                        device_type: this.DeviceType,
                        client_id: this.$route.params.clientId,
                    }
                }).then(async response => {
                    this.documentList = [];
                    this.documentList = this.sortedDocumentList(response.data);
                    this.isEverythingReady = true;
                });
            },
            handleFileChange(event) {
                this.fileSelected = event.target.files[0];

                const fileSize = this.fileSelected.size / (1024 * 1024);

                if(fileSize > 25) {
                    this.$toastr('error', 'The file selected is too large, please select a file under 25MB.');
                    this.fileSelected = null;
                }

                if (this.fileSelected) {
                    this.selectedFileName = event.target.files[0].name;
                } else {
                    this.selectedFileName = '';
                }
            },
            handleRowClick(event) {
                const row = event.target.closest('tr');
               
                if (row) {
                    const rowIndex = Array.from(row.parentNode.children).indexOf(row);
                    const item = this.documentList[rowIndex];
                    
                    this.downloadDocument(item['id'], item['file_name']);
                }
            },
            readFileAsync(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            },
            formatDate(timestamp) {
                const date = new Date(timestamp);
                const options = { day: '2-digit', month: 'short', year: '2-digit' };
                const formattedDate = date.toLocaleDateString('en', options);
                const parts = formattedDate.split(', ');
                const [month, day] = parts[0].split(' ');

                return `${day} ${month} ${parts[1]}`;
            },
            triggerFileInputClick() {
                document.getElementById('fileInput').click();
            },
            sortedDocumentList(documentList) {
                let sortedFileData = [...documentList];

                sortedFileData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

                sortedFileData.forEach(item => {
                    item.document_name = `<p class="font-weight-bold blue-500">${item.document_name}</p>`;
                    item.created_at = this.formatDate(item.created_at);
                });

                return sortedFileData;
            },
            clientPage() {
                this.$router.push('/client/details/' + this.$route.params.clientId);
            },

        },
    };
</script>